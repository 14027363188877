import React from "react"
import { ThemeProvider } from "styled-components"
import { Modal } from "@redq/reuse-modal"
import { theme } from "common/theme/appModern"
import { ResetCSS } from "common/assets/css/style"
import Sticky from "react-stickynode"
import AppModern from "./appmodern"
import termsandConditions from "./termsAndPolicies"
import GlobalStyle, {
  AppWrapper,
  ContentWrapper,
} from "containers/AppModern/appModern.style"

import "@redq/reuse-modal/es/index.css"
import "@fortawesome/fontawesome-free/css/all.min.css"

import Seo from "components/seo"

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <>
        <Seo title="Dayra" />
        <Modal />
        <ResetCSS />
        <GlobalStyle />
        <AppModern/>
        {/* <Route path="/terms" element={<termsandConditions />} /> */}
      </>
    </ThemeProvider>
  )
}
export default App
